<template>
<li
  :id="item.id"
  :data-type="item.monument_type"
  v-bind:class="['monument card', { visited: visited.indexOf(item.id) >= 0 }]">
  <router-link
    :to="item.monument_type != 'figure' ? { name: 'FigurePage', params: { id: item.id, term: term }} : { name: 'BioPage', params: { name: item.title, id: item.id }}"
    >
    <h1 class="title mobile" v-html="item.title"></h1>
    <figure
      :class="'img-container ' + (item.monument_type != 'artifact' ? 'round' : 'square')"
      v-lazy-container="{ selector: 'img' }"
      >
      <img
        v-if="item.monument_type === 'artifact'"
        class="lazy-image" :data-src="item.thumbnail_src"
        :data-loading="require('@/assets/img/thumbnail-placeholder.png')"
        />
      <img
        v-if="item.monument_type != 'artifact'"
        class="lazy-image" :data-src="item.thumbnail_src"
        :data-loading="require('@/assets/img/thumbnail-placeholder-curriculum.png')"
        :data-error="require('@/assets/img/thumbnail-placeholder-curriculum.png')"
        />
    </figure>
    <div v-if="item.monument_type === 'artifact'" :class="'material-icon media-type ' + (item.media_type === '3d' ? 'model' : item.media_type)">{{ item.media_type | mediaType }}</div>
    <div v-if="item.monument_type != 'artifact'" :class="'material-icon media-type ' + item.monument_type"><span>{{ item.monument_type | monumentType }}</span></div>
    <h1 class="title" v-html="item.title"></h1>
    <p class="description" v-if="item.subtitle != null" v-html="item.subtitle"></p>
    <p class="description" v-if="item.subtitle === null && item.description != null" v-html="item.description"></p>
    <div class="meta-container" v-if="item.monument_type === 'artifact'">
      <hr>
      <span class="identifier material-icon location" v-if="!!item.location" v-html="item.location"></span>
      <span class="identifier material-icon date">{{ item.date | formatDate(item.date_circa) }}</span>
    </div>
    <div class="curriculum-button" v-if="item.monument_type != 'artifact'">Learn More</div>
    <p class="description mobile" v-if="item.subtitle != null" v-html="item.subtitle"></p>
    <p class="description mobile" v-if="item.subtitle === null && item.description != null" v-html="item.description"></p>
    <div class="source-container" v-if="item.monument_type === 'artifact'">
      <div class="source-info">
        <span :class="'source-label value_' + item.source_rank" id="source-rank" v-if="!!item.source_rank">{{ item.source_rank | sourceRank }}</span>
      </div>
    </div>
  </router-link>
</li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Card',
  props: {
    item: Object
  },
  computed: mapGetters([
    'term',
    'visited'
  ])
}
</script>

<style lang="scss">

span.source-label {
  text-transform: capitalize;
}

</style>
